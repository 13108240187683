package com.hyperlightresearch.kobweb.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import com.hyperlightresearch.kobweb.HeadlineTextStyle
import com.hyperlightresearch.kobweb.SubheadlineTextStyle
import com.hyperlightresearch.kobweb.components.layouts.PageLayout
import com.hyperlightresearch.kobweb.toSitePalette

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle by ComponentStyle {
    base { Modifier.fillMaxWidth().gap(2.cssRem) }
    Breakpoint.MD { Modifier.margin { top(20.vh) } }
}

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle by ComponentStyle.base {
    Modifier
        .gap(0.5.cssRem)
        .width(70.cssRem)
        .height(18.cssRem)
}

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle by ComponentStyle.base {
    Modifier
        .backgroundColor(GridCellColorVar.value())
        .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
        .borderRadius(1.cssRem)
}

@Composable
private fun GridCell(color: Color, row: Int, column: Int, width: Int? = null, height: Int? = null) {
    Div(
        HomeGridCellStyle.toModifier()
            .setVariable(GridCellColorVar, color)
            .gridItem(row, column, width, height)
            .toAttrs()
    )
}
@Page("InfinityEngine")
@Composable
fun InfinityEngine() {
    PageLayout("InfinityEngine") {
		SpanText("lkjsfd")
	}
}
@Page
@Composable
fun HomePage() {
    PageLayout("Home") {
        Row(HeroContainerStyle.toModifier()) {
            Box {
                val sitePalette = ColorMode.current.toSitePalette()

                Column(Modifier.gap(2.cssRem)) {
                    Div(HeadlineTextStyle.toAttrs()) {
                        SpanText(
                            "Welcome to ", Modifier.color(
                                when (ColorMode.current) {
                                    ColorMode.LIGHT -> Colors.Black
                                    ColorMode.DARK -> Colors.White
                                }
                            )
                        )
                        SpanText(
                            "HyperLight Research",
                            Modifier
                                .color(sitePalette.brand.accent)
                                // Use a shadow so this light-colored word is more visible in light mode
                                .textShadow(0.px, 0.px, blurRadius = 0.5.cssRem, color = Colors.Gray)
                        )
                    }

                    val ctx = rememberPageContext()

                    Div(SubheadlineTextStyle.toAttrs()) {
                        SpanText("Look for our new game soon, built on the ")
						Button(onClick = {
							//ctx.router.tryRoutingTo("/about")
						}, colorScheme = ColorSchemes.Blue) {
							Text("Infinity Engine�")
						}
                    }

//                    Button(onClick = {
//                        // Change this click handler with your call-to-action behavior
//                        // here. Link to an order page? Open a calendar UI? Play a movie?
//                        // Up to you!
//                        ctx.router.tryRoutingTo("InfinityEngine")
//                    }, colorScheme = ColorSchemes.Blue) {
//                        Text("This could be your CTA")
//                    }
                }
            }

            //Div(HomeGridStyle
            //    .toModifier()
            //    .displayIfAtLeast(Breakpoint.MD)
            //    .grid {
            //        rows { repeat(3) { size(1.fr) } }
            //        columns { repeat(5) {size(1.fr) } }
            //    }
            //    .toAttrs()
            //) {
            //    val sitePalette = ColorMode.current.toSitePalette()
            //    GridCell(sitePalette.brand.primary, 1, 1, 2, 2)
            //    GridCell(ColorSchemes.Monochrome._600, 1, 3)
            //    GridCell(ColorSchemes.Monochrome._100, 1, 4, width = 2)
            //    GridCell(sitePalette.brand.accent, 2, 3, width = 2)
            //    GridCell(ColorSchemes.Monochrome._300, 2, 5)
            //    GridCell(ColorSchemes.Monochrome._800, 3, 1, width = 5)
            //}
        }
    }
}
