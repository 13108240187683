package com.hyperlightresearch.kobweb.components.sections

import androidx.compose.runtime.*
//import com.varabyte.kobweb.browser.dom.ElementTarget
//import com.varabyte.kobweb.compose.css.functions.clamp
//import com.varabyte.kobweb.compose.foundation.layout.Column
//import com.varabyte.kobweb.compose.foundation.layout.Row
//import com.varabyte.kobweb.compose.foundation.layout.Spacer
//import com.varabyte.kobweb.compose.ui.Alignment
//import com.varabyte.kobweb.compose.ui.Modifier
//import com.varabyte.kobweb.compose.ui.graphics.Colors
//import com.varabyte.kobweb.compose.ui.modifiers.*
//import com.varabyte.kobweb.silk.components.animation.Keyframes
//import com.varabyte.kobweb.silk.components.animation.toAnimation
//import com.varabyte.kobweb.silk.components.graphics.Image
//import com.varabyte.kobweb.silk.components.icons.CloseIcon
//import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
//import com.varabyte.kobweb.silk.components.icons.MoonIcon
//import com.varabyte.kobweb.silk.components.icons.SunIcon
//import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
//import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
//import com.varabyte.kobweb.silk.components.navigation.Link
//import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
//import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
//import com.varabyte.kobweb.silk.components.overlay.Overlay
//import com.varabyte.kobweb.silk.components.overlay.OverlayVars
//import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
//import com.varabyte.kobweb.silk.components.overlay.Tooltip
//import com.varabyte.kobweb.silk.components.style.ComponentStyle
//import com.varabyte.kobweb.silk.components.style.base
//import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
//import com.varabyte.kobweb.silk.components.style.toModifier
//import com.varabyte.kobweb.silk.theme.colors.ColorMode
//import org.jetbrains.compose.web.css.*
//import com.hyperlightresearch.kobweb.components.widgets.IconButton
//import com.hyperlightresearch.kobweb.toSitePalette
//
//val NavHeaderStyle by ComponentStyle.base {
//    Modifier.fillMaxWidth().padding(1.cssRem)
//}
//
//@Composable
//private fun NavLink(path: String, text: String) {
//    Link(path, text, variant = UndecoratedLinkVariant.then(UncoloredLinkVariant))
//}
//
//@Composable
//private fun MenuItems() {
//    NavLink("/", "Home")
//    NavLink("/about", "About")
//}
//
//@Composable
//private fun ColorModeButton() {
//    var colorMode by ColorMode.currentState
//    IconButton(onClick = { colorMode = colorMode.opposite },) {
//        if (colorMode.isLight) MoonIcon() else SunIcon()
//    }
//    Tooltip(ElementTarget.PreviousSibling, "Toggle color mode", placement = PopupPlacement.BottomRight)
//}
//
//@Composable
//private fun HamburgerButton(onClick: () -> Unit) {
//    IconButton(onClick) {
//        HamburgerIcon()
//    }
//}
//
//@Composable
//private fun CloseButton(onClick: () -> Unit) {
//    IconButton(onClick) {
//        CloseIcon()
//    }
//}
//
//val SideMenuSlideInAnim by Keyframes {
//    from {
//        Modifier.translateX(100.percent)
//    }
//
//    to {
//        Modifier
//    }
//}
//
//// Note: When the user closes the side menu, we don't immediately stop rendering it (at which point it would disappear
//// abruptly). Instead, we start animating it out and only stop rendering it when the animation is complete.
//enum class SideMenuState {
//    CLOSED,
//    OPEN,
//    CLOSING;
//
//    fun close() = when (this) {
//        CLOSED -> CLOSED
//        OPEN -> CLOSING
//        CLOSING -> CLOSING
//    }
//}
//
@Composable
fun NavHeader() {
//    Row(NavHeaderStyle.toModifier(), verticalAlignment = Alignment.CenterVertically) {
//        Link("https://kobweb.varabyte.com") {
//            // Block display overrides inline display of the <img> tag, so it calculates centering better
//            Image("/kobweb-logo.png", "Kobweb Logo", Modifier.height(2.cssRem).display(DisplayStyle.Block))
//        }
//
//        Spacer()
//
//        Row(Modifier.gap(1.5.cssRem).displayIfAtLeast(Breakpoint.MD), verticalAlignment = Alignment.CenterVertically) {
//            MenuItems()
//            ColorModeButton()
//        }
//
//        Row(
//            Modifier
//                .fontSize(1.5.cssRem)
//                .gap(1.cssRem)
//                .displayUntil(Breakpoint.MD),
//            verticalAlignment = Alignment.CenterVertically
//        ) {
//            var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }
//
//            ColorModeButton()
//            HamburgerButton(onClick =  { menuState = SideMenuState.OPEN })
//
//            if (menuState != SideMenuState.CLOSED) {
//                SideMenu(
//                    menuState,
//                    close = { menuState = menuState.close() },
//                    onAnimationEnd = { if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED }
//                )
//            }
//        }
//    }
}
//
//@Composable
//private fun SideMenu(menuState: SideMenuState, close: () -> Unit, onAnimationEnd: () -> Unit) {
//    Overlay(
//        Modifier
//            .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
//            .onClick { close() }
//    ) {
//        key(menuState) { // Force recompute animation parameters when close button is clicked
//            Column(
//                Modifier
//                    .fillMaxHeight()
//                    .width(clamp(8.cssRem, 33.percent, 10.cssRem))
//                    .align(Alignment.CenterEnd)
//                    // Close button will appear roughly over the hamburger button, so the user can close
//                    // things without moving their finger / cursor much.
//                    .padding(top = 1.cssRem, leftRight = 1.cssRem)
//                    .gap(1.5.cssRem)
//                    .backgroundColor(ColorMode.current.toSitePalette().nearBackground)
//                    .animation(
//                        SideMenuSlideInAnim.toAnimation(
//                            duration = 200.ms,
//                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
//                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
//                            fillMode = AnimationFillMode.Forwards
//                        )
//                    )
//                    .borderRadius(topLeft = 2.cssRem)
//                    .onClick { it.stopPropagation() }
//                    .onAnimationEnd { onAnimationEnd() },
//                horizontalAlignment = Alignment.End
//            ) {
//                CloseButton(onClick = { close() })
//                Column(Modifier.padding(right = 0.75.cssRem).gap(1.5.cssRem).fontSize(1.4.cssRem), horizontalAlignment = Alignment.End) {
//                    MenuItems()
//                }
//            }
//        }
//    }
//}
